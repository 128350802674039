
#root{
  bottom: 0;
  cursor:none;
}
@font-face {
  font-family: 'TitleFont';
  src: URL('fonts/Cookie-Regular.ttf') format('truetype');
}
html {
  -webkit-font-smoothing: antialiased;
  user-select: none;
}


.h1{
  font-family: "ChristmasFont";
}
.title-text{
  font-family: "TitleFont";
  font-size: 8vh;
  line-height: 0.9;
  font-weight: 800;
  margin-top: 55%;
  text-align: center;

}
.pin-text{
  font-family: "TitleFont";
  font-size: 6vh;
  font-weight: 800;
  margin-top: 55%;
  text-align: center;

}

.tree-container{
  width: 100%;
  height: 100%;
  background: url('pictures/Julekalender_canvas_plain_blurred.png');
  position: absolute;
  background-size: 100% 100%;
  z-index: 1;
  opacity: 0.4;
}
.sparkle-view-container{
  width: 100%;
  height: 100%;
  z-index: 5;
  position:fixed;
  opacity: 1;
  top:0;
  
  pointer-events: none;

}
.logo-tree-container{
  width: 100%;
  height: 100%;
  z-index: 0;
  position:absolute;
  opacity: 0.4;
}
.login-screen-items{
  width: 100%;
  height: 100%;
  z-index: 1;
  position:absolute;
}
.tree{
  position:absolute;
}
.bell-parent{
  width:100%;
  top: 3%;
  position:absolute;
}
.pin-field
{
  top:5%;
  position: relative;
  display: flex;
  justify-content:center;
  flex-direction: row;
  align-items: center;
  
}
.pin-area
{
  width: 6vh;
  height: 6vh;
  border-radius: 3vh;
  border: 2px solid black;
  margin: 0.6vh;

}
.pin-unit{
  width: 4vh;
  height: 4vh;
  margin: auto;
  margin-top: 0.75vh;
  border-radius: 2vh;
  background: black;
}
.global-pin-input{
  top:0;
  left:0;
  position: fixed;
  height:100vh;
  width:100vw;
  opacity: 0;
  pointer-events: none;
}
.sparkle{
  z-index: 1000;
}
#base {
  background: red;
  display: inline-block;
  height: 30vw;
  position: relative;
  border-radius: 10px;
  width: 30vw;
}
#base:before {
  border-bottom: 15vw solid red;
  border-left: 15vw solid transparent;
  border-right: 15vw solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 0;
  top:-14.75vw;
}
.window-holder-parent{
  z-index: 3;
}
.fallback-items{
  width: 100%;
  height: 100%;
  position: absolute;
}


