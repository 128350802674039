
  .tag-container:hover .tag {
      -webkit-transform: rotateY(15deg);
      transform: rotateY(15deg);
  }
  @font-face {
    font-family: 'TitleFont';
    src: URL('/src/fonts/Cookie-Regular.ttf') format('truetype');
  } 
  .tag {
    width: 30vw;
    height: 80vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    font-family: "TitleFont";
    justify-content: center;
    align-items: center;
    border-radius: 0.35rem;
    transform-style: preserve-3d;
    transition: 800ms;
    margin-top: 5%;
    transform: perspective(1000px) rotateY(var(--rotate-y,0))
      translateY(var(--translate-y,0));

    z-index: 10;
  }
  .tag:hover{
    --rotate-y:-2;
  }
  .tag.flip{
    --rotate-y:180deg;
  }
  .tag .front {
    background-color: #b6dfde;
  }
  .tag .front,
  .tag .back {
    position: absolute;
    backface-visibility: hidden;
    border-radius: 0.35rem;
    width: 100%;
    height: 100%;
    
    
  }
  .tag .back {
    transform: rotateY(180deg);
    transform: rotateY(180deg);
    background-color: #e44f47;
    
  }
  
  /* Tag 1 */


.tag-1-text {
    width: 100%;
    padding: 0 20px 0px 20px;
    bottom:0px;
    font-size: 50px;
    position: absolute;
    color: #222;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.click-me-text
{
   font-size: 32px;
   text-align: center;
    margin-top: 15%;
   color: rgba(0, 0, 0, 0.5);
}

.tag-1-text-back {
    width: 100%;
    padding: 0 20px;
    bottom: 0;
    font-size: 50px;
    color: #222;
    text-align: center; 
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
 
  
  /* Extras */
  .rule-shape {
      width: 100%;
      color: #fff;
      font-size: 34px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      align-self: flex-end;
  }
  
  .rule-shape:before,
  .rule-shape:after {
      content: " ";
      background: #fff;
      height: 1px;
      margin-bottom: 8px;
      display: block;
      -webkit-box-flex: 2;
      flex-grow: 2;
  }
  
  .rule-shape:before {
      margin-right: 6.25px;
  }
  
  .rule-shape:after {
      margin-left: 6.25px;
  }
  
  .rule-red {
      color: #e44f47;
  }
  
  .rule-red:before,
  .rule-red:after {
      background: #e44f47;
  }
