.blanker
{
    position:fixed;
    top:0%;
    left:0%;
    width: 100vw;
    height: 100vh;
    background-color: aliceblue;
    z-index: 1000;
    cursor:none;
}
.minimize-button
{
    position: fixed;
    top: 5%;
    right: 5%;
    opacity: 0;
    width: 100px;
    height: 100px;
    z-index: 4;
}
.minimize-button:hover
{
    scale: 1.1;
}

.book-control-left{
    width: 100px;
    height: 100px;
    position:fixed;
    z-index: 7;
    opacity: 0;
    left: 5%;
    top: 45%;
  }
  .book-control-right{
    width: 100px;
    height: 100px;
    position:fixed;
    z-index: 7;
    opacity: 0;
    right: 5%;
    top: 45%;
  }
  .book-control-right:hover{
    scale: 1.1;
  }
  .book-control-left:hover{
    scale: 1.1;
  }
  .sparkle-view{
    position:absolute;
    z-index: 50;
  }
  .sparkle{
    z-index: 9000;
  }
  .canvas{
    position: absolute;
  }
  .gridLayout{
    height: 100%;
  }
  .window-parent
{
  width: 100%;
  height: 100%;
  background: url('pictures/Julekalender_canvas_plain.png');
  position: absolute;
  background-size: 100% 100%;
  z-index: 2;
}
  