.book {
  width: 35vw;
  height: 80vh;
  z-index: 2;
  position: fixed;
  opacity: 0;
  top: 50%;
  left: 77%;
  transform: translate(-77%, -50%);
  font-family: "Aquifer";
}

@font-face {
  font-family: 'ChristmasFont';
  src: URL('fonts/Mountains-Of-Christmas.ttf') format('truetype');
}
@font-face {
  font-family: 'Aquifer';
  src: URL('fonts/Aquifer.ttf') format('truetype');
}

.illustration{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width:80%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
p{
  margin-top: 2vh;
  margin-left: 1vw;
  margin-right: 1vw;
  font-size: 3vh;
  color: #000000;
}
.page {
  transition: all .5s;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  background-color: #111111;
  float: left;
  margin-bottom: 0.5em;
  background: left top no-repeat;
  background-size: cover;
  z-index: 4;

}
h1 {
  margin-left: 2vw;
  margin-right: 2vw;
  font-size: 3vw;
  color: #000000;
  text-align: left;
}
h2 {
  margin-left: 2vw;
  margin-right: 2vw;
  font-size: 2vw;
  color: #000000;
  text-align: left;
}
h3 {
  margin-left: 2vw;
  margin-right: 2vw;
  font-size: 1.5vw;
  color: #000000;
  text-align: left;
}
.page:nth-child(even) {
  clear: both;
}
.book {
  perspective: 500vw;
}
.book .pages {
  width: 200%;
  height: 100%;
  
  /*transform: translate(-50%,0%);*/
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  border-radius: 4px;
  z-index: 3;
  /*box-shadow: 0 0 0 1px #e3dfd8;*/
}
.book .page {
  float: none;
  clear: none;
  margin: 0;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  transform-origin: 0 0;
  transition: transform 1.4s;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  background-color: white;
}

.book .page:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 0.7s;
}
.book .page:nth-child(odd) {
  transform: rotateY(0deg);
  right: 0;
  border-radius: 0 8px 8px 0;
  background-size: 100% 100%;

  background-image: linear-gradient(to right, rgba(0,0,0,.15) 0%, rgba(0,0,0,0) 10%), url(pictures/Page_Right_Tex3.jpg);
}
.book .page:nth-child(odd):before {
  background: rgba(0, 0, 0, 0);
}
/*, 12px 0 15px -4px rgba(31, 73, 125, 0.8);
*/
/*
  box-shadow: 0 9px 15px -4px black, 0 -9px 15px -4px black, 12px 0 15px -4px black;
  */
.book .page:nth-child(even) {
  transform: rotateY(179deg);
  transform-origin: 100% 0;
  left: 0;
  border-radius: 8px 0px 0px 8px;
  border-color: black;
  background-size: 100% 100%;
  background-image: linear-gradient(to left, rgba(0,0,0,.2) 0%, rgba(0,0,0,0) 10%), url(pictures/Page_Left_Tex2.jpg);
}
.book .page:nth-child(even):before {
  background: rgba(0, 0, 0, 0.2);
}
.book .page.grabbing {
  transition: none;
}
.book .page.flipped:nth-child(odd) {
  transform: rotateY(-180deg);
}
.book .page.flipped:nth-child(odd):before {
  background: rgba(0, 0, 0, 0.2);
}
.book .page.flipped:nth-child(even) {
  pointer-events: all;
  transform: rotateY(0deg);
}
.book .page.flipped:nth-child(even):before {
  background: rgba(0, 0, 0, 0);
}
*,
* :before,
*:after {

  
  box-sizing: border-box;
}
.page:nth-child(odd){
  background-position: right top;
}
.video-player-parent{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: select;
 
}
.video-player{
  width:90%;
  height: 45%;
  -webkit-transform: translate3d(0px, 0px, 1px);
  transform: translate3d(0px, 0px, 1px);
}
#front-cover
{
  background-image: linear-gradient(to left, rgba(0,0,0,.2) 0%, rgba(0,0,0,0) 10%), url(pictures/book_front.png);
}
#back-cover
{
  background-image: linear-gradient(to left, rgba(0,0,0,.2) 0%, rgba(0,0,0,0) 10%), url(pictures/book_back.png);
}