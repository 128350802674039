.window-text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "ChristmasFont";
    font-size: 500%;
    opacity: 0.6;
    z-index: 1;
    color: white;
}
.window-text-lit{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "ChristmasFont";
  font-size: 500%;
  opacity: 1;
  z-index: 1;
  color: white;
}
.window-border-effect
{
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}

.container-maximized {
    width:100vw;
    height:100vh;
    background: rgba(0, 0, 0, 0.4);
    position:fixed;
    top:0;
    left:0;
    z-index: 2;
    
  }
  .container {
    width: 100%;
    height: 100%;
    
  }
  .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transform-style: preserve-3d;
  
    transition: 150ms;
    transform: perspective(1000px) rotateY(var(--rotate-y,0))
      translateY(var(--translate-y,0));
  }
  #clip{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background: url('pictures/Julekalender_canvas_lys.png');
  }
  
  