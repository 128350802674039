*, *:after, *:before {
    box-sizing: border-box;
  }
  
  .labels {
      flex-wrap: wrap;
      -webkit-box-pack: center;
      justify-content: center;
      display: -webkit-box;
      display: flex;
      bottom:0;
      right: 0;
      position:fixed;
      -webkit-transform: rotateZ(15deg);
      transform: rotateZ(15deg);
  }
  
  /* Shared */
  .label-container {
      width: 19vh;
      height: 23vh;
      margin: 10px;
      position: relative;
      -webkit-perspective: 800px;
      perspective: 800px;
  }
  
  .label {
      width: 100%;
      height: 100%;
      position: absolute;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: -webkit-transform 1s;
      transition: -webkit-transform 1s;
      transition: transform 1s;
      transition: transform 1s, -webkit-transform 1s;
  }
  
  .label-container:hover .label {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
  }
  
  .label-side {
      width: 100%;
      height: 100%;
      position: absolute;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
  }
  
  .label-side.is-back {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      z-index: 2;
  }
  
  .label-text {
      width: 100%;
      padding: 0 10px;
      color: #222;
      
      font: 3.8vh 'Sacramento', cursive;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  
  
  /*.label 2 */
  .label-2-side:before{
    content: " ";
      background: #b6dfde;
      height: 50px;
      position: absolute;
      top: 0;
      left: 29px;
      right: 29px;
      -webkit-transform: skew(-20deg) translate3d(0, 0, 0);
      transform: skew(-30deg) translate3d(0, 0, 0);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
  }
  .label-2-side:after {
      content: " ";
      background: #b6dfde;
      height: 50px;
      position: absolute;
      top: 0;
      left: 29px;
      right: 29px;
      -webkit-transform: skew(-20deg) translate3d(0, 0, 0);
      transform: skew(-30deg) translate3d(0, 0, 0);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
  }
  
  
  .label-2-side.is-back:before,
  .label-2-side.is-back:after {
      background: #47ada0;
  }
  
  .label-2-side:after {
      -webkit-transform: skew(20deg);
      transform: skew(30deg);
  }
  
  .label-2-text:before {
      content: " ";
      background: #fff;
      width: 27px;
      height: 27px;
      border: 6px solid #47ada0;
      border-radius: 50%;
      position: absolute;
      top: 20px;
      left: 50%;
      z-index: 1;
      -webkit-transform: translate3d(-50%, 0, 0);
      transform: translate3d(-50%, 0, 0);
  }
  
  .label-2-side.is-back .label-2-text:before {
      border-color: #b6dfde;
  }
  
  .label-2-text {
      background: #b6dfde;
      margin-top: 50px;
      padding-bottom: 30px;
      font-family: "TitleFont";
  }
  
  .label-2-side.is-back .label-2-text {
      background: #47ada0;
  }
  
  /* Extras */
  .rule-shape {
      width: 100%;
      color: #fff;
      font-size: 34px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      align-self: flex-end;
  }
  
  .rule-shape:before,
  .rule-shape:after {
      content: " ";
      background: #fff;
      height: 1px;
      margin-bottom: 8px;
      display: block;
      -webkit-box-flex: 2;
      flex-grow: 2;
  }
  
  .rule-shape:before {
      margin-right: 6.25px;
  }
  
  .rule-shape:after {
      margin-left: 6.25px;
  }
  
  .rule-red {
      color: #e44f47;
  }
  
  .rule-red:before,
  .rule-red:after {
      background: #e44f47;
  }
  
  .rule-diagonal {
      background: -webkit-repeating-linear-gradient(
          45deg,
          #e44f47,
          #e44f47 7px,
          transparent 7px,
          transparent 14px,
          #fff 14px,
          #fff 21px,
          transparent 21px,
          transparent 28px
      );
      background: repeating-linear-gradient(
          45deg,
          #e44f47,
          #e44f47 7px,
          transparent 7px,
          transparent 14px,
          #fff 14px,
          #fff 21px,
          transparent 21px,
          transparent 28px
      );
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
  }